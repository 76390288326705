<mat-card class="card my-4">
  <mat-card-title class="card-title">Building plan</mat-card-title>
  <div class="building-plan-wrapper mb-6">
    <div class="flex justify-between border-b mb-2 items-center">
      <mat-button-toggle-group
        class="desktop-toggle-group"
        name="incident-status"
        aria-label="Incident Status"
        [value]="showArchived">
        <mat-button-toggle [value]="false" (change)="showArchived = !showArchived" class="test-incident-status-open">
          <span class="item-status-title">Current</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="true" (change)="showArchived = !showArchived" class="test-incident-status-history">
          <span class="item-status-title">Archived</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <div>
        <app-search-input (searchDataEvent)="searchUpdate($event)" placeholder="Search Floor"></app-search-input>
      </div>
    </div>

    <div class="plan-header">
      <button
        appRestrictedEl
        [routerLink]="['edit']"
        class="hover:bg-ui-green-light hover:text-white"
        [class.invisible]="showArchived">
        <mat-icon matPrefix>add</mat-icon>
        <strong class="text-xs"> Add Floor</strong>
      </button>
      <h5 class="text-center font-nunito text-sm font-semibold text-ui-gray-450 h-6 leading-6">Rooms</h5>
    </div>

    <div *ngIf="locationData.floors | filterFloorSpaceByName : floorSearch : showArchived as floorList">
      <div class="flex">
        <div class="floors-sidebar">
          <ul>
            <li
              *ngFor="let floor of floorList"
              class="floor-name text-ellipsis overflow-hidden px-2 whitespace-nowrap w-[101px]"
              [routerLink]="['/dashboard', locationData.id, floor.id]">
              {{ floor.friendlyName }}
            </li>
          </ul>
        </div>
        <div class="rooms-container">
          <ul>
            <li *ngFor="let floor of floorList">
              <ul class="test-floor-{{ floor.id }}">
                <li
                  *ngFor="let room of floorsWithSortedRooms[floor.id] | filterRoomsByName : '' : showArchived"
                  class="inline-block hover:cursor-pointer"
                  [ngClass]="floor.status !== EntityStatus.Archived ? room.status : floor.status"
                  [class.incident-new]="room.status === EntityStatus.Active && room.incidentCountByStatuses.newCount"
                  [class.incident-inProgress]="
                    room.status === EntityStatus.Active &&
                    room.incidentCountByStatuses.inProgressCount &&
                    !room.incidentCountByStatuses.newCount
                  "
                  [routerLink]="['/dashboard', locationData.id, floor.id, room.id]"
                  matTooltipClass="multiline-tooltip"
                  [matTooltip]="
                    room.friendlyName +
                    (!showArchived
                      ? ':&#10;' +
                        (room.incidentCountByStatuses.newCount + room.incidentCountByStatuses.inProgressCount) +
                        ' inc | ' +
                        room.devicesCount +
                        ' dev'
                      : '')
                  "></li>
                <li
                  *ngFor="
                    let emptyRoom of (floorsWithSortedRooms[floor.id] | filterRoomsByName : '' : showArchived)?.length
                      | emptySpacesBuildingPlan : roomFitsNumber
                  "
                  class="empty-room inline-block"></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <app-no-data [resultContent]="!!floorSearch" *ngIf="!floorList.length" noDataText="No Floors yet" />
    </div>
  </div>
</mat-card>
